/* eslint-disable no-console */
import axios from 'axios';
import { LOG, BASE_URL } from 'config/constants';

axios.defaults.withCredentials = true;

const getUrl = (url: string): string => {
  return BASE_URL.concat('/').concat(url);
};

const _handleError = (error: any, reject: (reason: any) => void) => {
  if (error.response && error.response.data) {
    if (LOG) {
      console.log(error.response.data);
    }
    reject(error.response.data);
    return;
  }
  if (LOG) {
    console.log(error);
  }
  reject(error);
};

const _checkResponse = (data?: { success?: boolean; code: string | number }) => {
  if (!data || !data.success || data.code !== 200) {
    throw new Error('Malformed Response');
  }
};

export const GET = <SuccessDataType>(
  url: string,
  options?: AxiosHelperOptions,
): Promise<SuccessDataType> => {
  if (LOG) {
    console.log('GET URL:', url, options);
  }
  return new Promise((resolve, reject) => {
    axios
      .get(getUrl(url), { ...options })
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) {
          console.log('Success:', data.data);
        }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};

export const POST = <SuccessDataType>(
  url: string,
  body?: any,
  options?: AxiosHelperOptions,
): Promise<SuccessDataType> => {
  if (LOG) {
    console.log('POST URL:', url, ',body: ', body);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(getUrl(url), body, { ...options })
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) {
          console.log('Success:', data.data);
        }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};

export const PUT = <SuccessDataType>(url: string, body: any): Promise<SuccessDataType> => {
  if (LOG) {
    console.log('PUT URL:', url, ',body: ', body);
  }
  return new Promise((resolve, reject) => {
    axios
      .put(getUrl(url), body)
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) {
          console.log('Success:', data.data);
        }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};

export const DELETE = <SuccessDataType>(url: string): Promise<SuccessDataType> => {
  if (LOG) {
    console.log('DELETE URL:', url);
  }
  return new Promise((resolve, reject) => {
    axios
      .delete(getUrl(url))
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) {
          console.log('Success:', data.data);
        }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};
