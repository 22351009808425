import React from 'react';
import { CSwitch as CoreSwitch } from '@coreui/react';

export const CSwitch = (props: {
  checked?: boolean;
  onChange?: () => void;
  color?: CustomTypes.ColorTypes;
  labelOff?: string;
  labelOn?: string;
}) => {
  return (
    <CoreSwitch
      checked={props.checked}
      onChange={props.onChange}
      size="sm"
      color={props.color || 'info'}
      shape="pill"
      style={{
        backgroundColor: '#000',
      }}
      labelOff={props.labelOff ?? 'No'}
      labelOn={props.labelOn ?? 'Yes'}
    />
  );
};
