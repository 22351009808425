import { PAGINATION_DEFAULT_PAGE_SIZE, SHIPROCKET_ORDER_STATUSES } from 'config/constants';
import { ORDER_STATUSES } from 'config/orderStatuses';
import { SHIPMENT_STATUSES } from 'config/shipmentStatuses';
import validate from 'validate.js';

/**
 * Function to get snake_case for string
 * @param  str String to be converted to snake_case
 * @return    String in snake_case
 */
export const getSnakeCase = (str: string): string => {
  const arr = str.split(' ');
  const newArr: string[] = [];
  arr.forEach((word) => {
    newArr.push(word.toLowerCase());
  });
  return newArr.join('_');
};

/**
 * Function to format bytes into human readable format
 * @param  bytes             Size in bytes
 * @return                   Human readable string representation of size
 */
export const formatSizeUnits = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Function to check if value passed is an invalid number or not
 * @param  id  value to be checked
 * @return     True if value is not a number, false otherwise.
 */
export const isValueNonNumeric = (value?: string | number): boolean => {
  // @ts-ignore
  return Number.isNaN(+value);
};

/**
 * Function to format currency from paise to Rupees.
 * Function take amount in paise and inserts decimal at 2 places from the end.
 * @param  value  Amount in paise.
 * @return        Amounnt formatted in Rupees.
 */
export const formatCurrency = (value?: number | null, amountOnly?: boolean) => {
  if (!value && value !== 0) {
    return;
  }
  if (+value === 0) {
    return '₹0.00';
  }
  const currencyInString = `${value}`;
  if (amountOnly) {
    return `${currencyInString.slice(0, -2)}.${currencyInString.slice(-2)}`;
  }
  const formattedValue = `₹${currencyInString.slice(0, -2)}.${currencyInString.slice(-2)}`;
  return formattedValue;
};

/**
 * Function to convert INTEGER amounts (in Paise) to floats with 2 decimal points (in Rupees)
 * @param  value             Amount in PAISE
 * @return                   Amount in rupees
 */
export const getAmountInRupees = (valueInPaise?: number | string) => {
  if (!valueInPaise && valueInPaise !== 0) {
    return 0.0;
  }
  if (+valueInPaise === 0) {
    return 0.0;
  }
  if (typeof valueInPaise === 'string') {
    return parseFloat((+valueInPaise / 100).toFixed(2));
  }
  return parseFloat((valueInPaise / 100).toFixed(2));
};

/**
 * Function to convert FLOAT amounts (in Rupees) to integer (in Paise)
 * @param  value             Amount in RUPEES
 * @return                   Amount in paise
 */
export const getAmountInPaise = (valueInRupees?: number | string) => {
  if (!valueInRupees && valueInRupees !== 0) {
    return 0;
  }
  if (+valueInRupees === 0) {
    return 0;
  }
  if (typeof valueInRupees === 'string') {
    return Math.round(parseFloat(valueInRupees) * 100);
  }
  return Math.round(valueInRupees * 100);
};

/**
 * Function to get default form state from formFields. Form fields must be an object of the shape defined in GenericFormFields type.
 * @param  formFields formFields to be used to extract default form state.
 * @return            default form state.
 */
export const getDefaultFormStateFromFormFields = (formFields: GenericFormFields) => {
  const defaultFormState: GenericObject = {};
  Object.getOwnPropertyNames(formFields).forEach((eachField) => {
    if (Array.isArray(formFields[eachField].defaultValue)) {
      defaultFormState[eachField] = [...formFields[eachField].defaultValue];
      return;
    }
    if (typeof formFields[eachField].defaultValue === 'object') {
      defaultFormState[eachField] = { ...formFields[eachField].defaultValue };
      return;
    }
    defaultFormState[eachField] = formFields[eachField].defaultValue;
  });
  return defaultFormState;
};

/**
 * Function to convert order id into order number.
 * Function pads order id with 0 to make 4 digit number and prefixes 275-
 * @param  orderId  Order id to be converted
 * @return          String representing order number.
 */
export const getOrderNumber = (orderId: number) => {
  const paddedId = (String(0).repeat(4) + String(orderId)).slice(String(orderId).length);
  return `275-${paddedId}`;
};

/**
 * Function to convert shipment id into shipment number.
 * Function pads shipment id with 0 to make 4 digit number and prefixes 275-
 * @param  shipmentId  Shipment id to be converted
 * @return          String representing order number.
 */
export const getShipmentNumber = (orderId: number, shipmentId: number) => {
  const orderNumber = getOrderNumber(orderId);
  return `${orderNumber}-${shipmentId}`;
};

/**
 * Function to get order id from order number string (275-xxxx)
 * @param   {string}          orderNumberString  Order number
 * @return  {number | false}                     order id if order number is valid, false if id could not be extracted.
 */
export const getOrderIdFromOrderNumber = (orderNumberString: string) => {
  const orderIdArray = orderNumberString.split('-');
  if (orderIdArray.length !== 2) {
    return false;
  }
  const orderIdString = orderIdArray[1];
  const orderId = +orderIdString;
  if (Number.isNaN(orderId)) {
    return false;
  }
  return orderId;
};

/**
 * Function to get shipment id from shipment number string (275-xxxx-xxx)
 * @param   {string}          shipmentNumberString  Shipment number
 * @return  {number | false}                     shipment id if shipment number is valid, false if id could not be extracted.
 */
export const getShipmentIdFromShipmentNumber = (shipmentNumberString: string) => {
  const shipmentIdArray = shipmentNumberString.split('-');
  if (shipmentIdArray.length !== 3) {
    return false;
  }
  const shipmentIdString = shipmentIdArray[2];
  const shipmentId = +shipmentIdString;
  if (Number.isNaN(shipmentId)) {
    return false;
  }
  return shipmentId;
};

/**
 * Function to break total into base amount & tax
 * @param  total           Total amount to be broken up (in PAISE)
 * @param  taxPercentage   Tax percentage for total
 * @return                 Object with total, baseAmount & tax
 */
export const getBaseAndTaxBreakupFromTotal = (totalInPaise?: number, taxPercentage?: number) => {
  if (!totalInPaise || !taxPercentage) {
    return {
      total: 0,
      baseAmount: 0,
      taxAmount: 0,
    };
  }
  const baseAmount = Math.round(totalInPaise / (1 + taxPercentage / 100));
  return {
    total: totalInPaise,
    baseAmount,
    taxAmount: totalInPaise - baseAmount,
  };
};

/**
 * Function to find a key value pair in array of objects.
 * @param   {Array}   arr    Array of object
 * @param   {string}  key    Key to look for
 * @param   {string}  value  Value for key to look for
 * @return  {number}         Index of first match found. -1 if not found
 */
export const findInArray = (arr: any[], key: string, value: string | number) => {
  const searchArr = arr.map((item: any) => item[key]);
  return searchArr.indexOf(value);
};

/**
 * Function to return paginated index for an item in paginated table.
 * Function returns index, offsetted by pageSize * currentPage
 * Function assumes that index of items on every page starts from 0, as is the case when paginated data is fetched using API.
 * @param   {number}  index        Index of item, starting at 0 on current page of table
 * @param   {number}  currentPage  Current page number
 * @return  {number}               Index of item after offsetting for current page number
 */
export const getPaginatedItemIndex = (
  index: number,
  currentPage?: number,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
) => {
  return ((currentPage || 1) - 1) * pageSize + index + 1;
};

/**
 * Helper function to check if array contains a value, or any of the keys provided
 * in the value object with corresponding value if array contains object
 * @param   array               array with values to be checked, should contain objects if value parameter is also object
 * @param   value               single value in case array contains primitive values, object if array contains objects
 * @param   [returnIdx=true]    should function return boolean or Index in case match is found
 * @return                      false in case match is not found, index or true otherwise based on value of returnIdx
 * TODO: Time complexity seems to be O(n^2). Find at least one benefit against findInArray method or deprecate this.
 */
export function arrayContains<T>(array: T[], value: T, returnIdx?: true): number | false;
export function arrayContains<T>(array: T[], value: T, returnIdx?: false): boolean;
export function arrayContains<T extends CustomTypes.Object>(
  array: T[],
  value: Partial<T>,
  returnIdx?: true,
): number | false;
export function arrayContains<T extends CustomTypes.Object>(
  array: T[],
  value: Partial<T>,
  returnIdx: false,
): boolean;
export function arrayContains<T>(array: T[], value: Partial<T>, returnIdx = true) {
  if (typeof value === 'object') {
    const matchedIdx = array.findIndex((eachDataElement) => {
      const allProperties = Object.getOwnPropertyNames(value);
      let found = false;
      for (
        let allPropertiesIdx = 0;
        allPropertiesIdx < allProperties.length;
        allPropertiesIdx += 1
      ) {
        if (
          // @ts-ignore
          eachDataElement[allProperties[allPropertiesIdx]] ===
          // @ts-ignore
          value[allProperties[allPropertiesIdx]]
        ) {
          found = true;
          break;
        }
      }
      return found;
    });
    if (matchedIdx !== -1) {
      return returnIdx ? matchedIdx : true;
    }
  }
  // @ts-ignore
  const primitiveValueIndex = array.indexOf(value);
  if (primitiveValueIndex !== -1) {
    return returnIdx ? primitiveValueIndex : true;
  }
  return false;
}

/**
 * Checks if value is empty.
 * Value is considered empty if it is one of the following:
 * null
 * undefined
 * empty string
 * whitespace only string
 * empty array
 * empty object.
 *
 * Refer http://validatejs.org/#utilities-is-empty for more details
 *
 * @param   {any}  value  Value to be checked
 *
 * @return  {boolean}      Whether value is empty or not
 */
export const valueIsEmpty = (value: any): value is null | undefined => {
  return validate.isEmpty(value);
};

/**
 * Function to convert weight in grams to kg as a string, padded with 0s.
 * @param   {number}  weight  Weight to be converted
 * @return  {string}          Weight in Kg.
 */
export const convertGramsToKg = (weight: number) => {
  const paddedWeight = (String(0).repeat(4) + String(weight)).slice(String(weight).length);
  return `${paddedWeight.slice(0, -3)}.${paddedWeight.slice(-3)}`;
};

/**
 * Function to check if a shiprocket order is considered active or not
 * Currently it considers order inactive only if it is in Cancelled or Cancellation Requested status
 * (Status refers to status from shiprocket)
 * @var     {Entities.Shiprocket}   shiprocketOrder   The order which needs to be checked
 * @return  {boolean}
 */
export const isShiprocketOrderActive = (shiprocketOrder: Entities.ShiprocketOrder) => {
  if (
    shiprocketOrder.status !== SHIPROCKET_ORDER_STATUSES.CANCELLED &&
    shiprocketOrder.status !== SHIPROCKET_ORDER_STATUSES.CANCELLATION_REQUESTED
  ) {
    return true;
  }
  return false;
};

/**
 * Function to check if order is shippable or not, ie. if Shipment can be generated or not.
 * Function checks if order status is Dispatch Pending or not.
 * @var     {Entities.Order}    order     Order which needs to be checked
 * @return  {boolean}
 */
export const isOrderShippable = (order: Entities.Order) => {
  if (
    order.status === ORDER_STATUSES.DISPATCH_PENDING ||
    order.status === ORDER_STATUSES.PICKUP_PENDING ||
    order.status === ORDER_STATUSES.DS_ERP_BILLING_PENDING
  ) {
    return true;
  }
  return false;
};

/**
 * Function to check if shipment is shippable or not, ie. if Shiprocket order can be generated or not.
 * Function checks if shipment status is Dispatch Pending or not.
 * @var     {Entities.Shipment}    shipment     Shipment which needs to be checked
 * @return  {boolean}
 */
export const isShipmentShippable = (shipment: Entities.Shipment) => {
  if (
    [
      SHIPMENT_STATUSES.DISPATCH_PENDING,
      SHIPMENT_STATUSES.PICKUP_PENDING,
      SHIPMENT_STATUSES.PICKUP_GENERATION_FAILED,
    ].includes(shipment.status)
  ) {
    return true;
  }
  return false;
};

/**
 * Function to remove specified index from array
 * @param      array      Array from which element needs to be removed
 * @param      idx        Index of element to be removed
 * @return                Array after removing specified index
 */
export const removeIdxFromArray = <T>(array: T[], idx: number): T[] => {
  const newArray = array.filter((_, itemIdx) => idx !== itemIdx);
  return newArray;
};

/**
 * Function to calculate the percentage of the total order amount that the discount is.
 * Function takes into account both wallet discount and discount amount.
 * Function divides the total discount by the total amount of the order (product_total_amount + delivery_charges)
 *
 * @param   {Entities.Order}         order         Order to be used for calculation
 * @return  {number}                               totalDiscount Total discount given on order
 */
export const calculateDiscountPercentage = (order: Entities.Order) => {
  const totalDiscount = order.wallet_discount + order.discount_amount;
  if (!totalDiscount) {
    return 0;
  }
  const discountPercentage = (
    (totalDiscount / (order.product_total_amount + order.delivery_charges)) *
    100
  ).toFixed(2);
  return parseFloat(discountPercentage);
};

/**
 * Function to calculate price of product to be mentioned in invoice.
 * This price takes is basically product_price - %age of discount given on order.
 * @param   {Entities.Order}         order       Order to be used for calculation
 * @param   {number}                 basePrice   Base price which needs to be factored for invoice
 * @return  {number}                             Price to be used in invoice, in Paise.
 */
export const getInvoicePrice = (order: Entities.Order, basePrice: number) => {
  const discountPercentage = calculateDiscountPercentage(order);
  const invoicePrice = Math.round(basePrice - basePrice * (discountPercentage / 100));
  return invoicePrice;
};
