import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { toggleSudoModalAction } from 'actions/app';

export function useDebounce(callback: (...args: any[]) => void, delay: number) {
  const debouncedFn = useCallback(
    debounce((...args: any[]) => callback(...args), delay),
    [delay], // will recreate if delay changes
  );
  return debouncedFn;
}

/**
 * Hook to toggle Sudo Access Modal. Hook internally calls redux action
 * @return  {function}  Function which takes a boolean argument and sets it as sudoModalVisible redux state value
 */
export function useToggleSudoModal() {
  const dispatch = useDispatch();
  const callbackFn = useCallback(
    (visible?: boolean) => {
      dispatch(toggleSudoModalAction(visible));
    },
    [dispatch],
  );
  return callbackFn;
}
