import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { CCol, CRow, CSpinner } from '@coreui/react';
import { TextInput } from 'components/common';
import { useDebounce } from 'utilities/CustomHooks';
import Logger from 'utilities/Logger';
import { valueIsEmpty } from 'utilities/helpers';
import { CIcon } from './CIcon';

export type DebouncedInputRef = {
  clearValue: () => void;
  setValue: (value: string) => void;
};

export const DebouncedInput = forwardRef(
  (
    { spinnerColor, onChange, iconName, ...textInputProps }: ComponentProps.DebouncedInputProps,
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    const [textInputValue, setTextInputValue] = useState('');
    const debouncedOnChange = useDebounce((newValue: string) => {
      onChange && onChange(!valueIsEmpty(newValue) ? newValue : undefined);
      setLoading(false);
    }, 1000);

    useImperativeHandle(
      ref,
      (): DebouncedInputRef => {
        return {
          clearValue: () => {
            Logger.debug('DebouncedInput', 'clearValue fired');
            setTextInputValue('');
          },
          setValue: (newValue: string) => {
            Logger.debug('DebouncedInput', 'setValue fired with', newValue);
            setTextInputValue(newValue);
          },
        };
      },
      [setTextInputValue],
    );

    return (
      <CRow className="align-items-center">
        <CCol xs={10} className="p-0">
          <TextInput
            {...textInputProps}
            value={textInputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTextInputValue(e.target.value);
              setLoading(true);
              debouncedOnChange(e.target.value);
            }}
          />
        </CCol>
        <CCol xs={2} className="p-0">
          {loading ? (
            <CSpinner size="sm" className="ml-2" color={spinnerColor} />
          ) : (
            iconName && <CIcon name={iconName} className="ml-2" />
          )}
        </CCol>
      </CRow>
    );
  },
);
