import React, { CSSProperties } from 'react';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import CIcon from '@coreui/icons-react';
import THEME from 'theme';

const dragStyle: CSSProperties = {
  height: '100%',
  width: '100%',
  borderWidth: 2,
  borderRadius: 10,
  borderColor: '#40dbf7',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  position: 'absolute',
  top: 0,
};

/**
 * Dropzone to handle file uploads
 * @prop          onDrop             Function which gets called when user drags'n'drops files or selects files from dialog after clicking
 * @prop          accept             string or array of strings representing allowed mimetypes
 * @prop          message            Message to be displayed in dropzone
 * @prop          singleFile         Whether or not multiple files can be selected in dialog or not. Defaults to false
 * @prop          size               Size of rendered drop zone. Defaults to large.
 */
export const Dropzone = ({
  onDrop,
  accept,
  message,
  singleFile = false,
  size = 'large',
  className,
}: {
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  accept?: string | string[];
  message?: string;
  singleFile?: boolean;
  size?: 'small' | 'large';
  className?: string;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });
  let containerClasses;
  let subContainerClasses;
  let messageComponent;
  let iconHeight;
  switch (size) {
    case 'large':
      containerClasses = 'd-flex flex-column';
      subContainerClasses = 'p-4 flex-column';
      messageComponent = <h6 className="mt-3">{message}</h6>;
      iconHeight = 64;
      break;
    case 'small':
      containerClasses = 'd-flex flex-row';
      subContainerClasses = 'p-2 flex-row w-100';
      messageComponent = (
        <span style={{ fontWeight: 'bold' }} className="ml-2">
          {message}
        </span>
      );
      iconHeight = 20;
      break;
    default:
      break;
  }
  return (
    <div {...getRootProps()}>
      <input
        className="dropzone-input"
        {...{
          ...getInputProps(),
          multiple: !singleFile,
        }}
      />
      <div
        className={`${containerClasses} ${className}`}
        style={{ position: 'relative', cursor: 'pointer' }}
      >
        <div
          className={`border rounded d-flex justify-content-center align-items-center ${subContainerClasses}`}
        >
          <CIcon name="cil-cloud-upload" height={iconHeight} />
          {message && messageComponent}
          <div className="mt-3">
            <ul>
              <li>
                <p style={{ color: THEME.COLORS.GREY }}>
                  Image assets: 1600x1600 pixels maximum, 400x400 pixels minimum.
                </p>
              </li>
              <li>
                <p style={{ color: THEME.COLORS.GREY }}>Aspect ratio: 1:1.</p>
              </li>
              <li>
                <p style={{ color: THEME.COLORS.GREY }}>Maximum file size: 2mB.</p>
              </li>
              <li>
                <p style={{ color: THEME.COLORS.GREY }}>
                  Allowed extensions: ‘jpg’, ‘png’, ‘jpeg’.
                </p>
              </li>
            </ul>
          </div>
        </div>
        {isDragActive && (
          <div style={dragStyle} className="d-flex flex-column justify-content-center">
            <div className="d-flex flex-row justify-content-center">
              <span style={{ fontSize: 24, color: 'white' }}>
                <b>Drop File here</b>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
