import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';

import sidebarReducer from 'reducers/sidebarReducer';
import authReducer from 'reducers/authReducer';
import userReducer from 'reducers/userReducer';
import appReducer from 'reducers/appReducer';

// function combineReducers(reducers) {
//   return (state = {}, action) => {
//     const newState = {};
//     Object.keys(reducers).forEach((eachKey) => {
//       newState[eachKey] = reducers[eachKey](state[eachKey], action);
//     });
//     return newState;
//   };
// }

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  auth: authReducer,
  User: userReducer,
  App: appReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default store;
export type RootState = ReturnType<typeof rootReducer>;
