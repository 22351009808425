import { GET, POST } from 'utilities/AxiosHelpers';

export const getOrdersApi = () => {
  return GET<Entities.Order[]>('admin/orders', {
    params: {
      include: 'customer',
    },
  });
};

export const getPaginatedOrdersApi = (
  page?: number,
  page_size?: number,
  queryParams?: Record<string, number | string | undefined>,
) => {
  return GET<CustomTypes.PaginatedResponse<Entities.Order>>('admin/orders', {
    params: {
      include: 'customer',
      paginate: true,
      page,
      page_size,
      ...queryParams,
    },
  });
};

export const getOrderApi = (id: number | string) => {
  return GET<Entities.Order>(`admin/orders/${id}`, {
    params: {
      include:
        'customer,billing_address,user.referrer,order_invoice,shipments,scheme,razorpay_order.razorpay_payments,order_events,order_remarks.creator',
    },
  });
};

export const AddOrderRemarksApi = (id: number | string, fields: Entities.OrderRemarkFormData) => {
  return POST<Entities.OrderRemark>(`admin/orders/${id}/order_remarks`, fields, {
    params: {
      include: 'creator',
    },
  });
};

export const markOrderAsCancellationRequested = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/cancellation_requested`);
};

export const markOrderAsCancellationAccepted = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/cancellation_accepted`);
};

export const markOrderAsCancellationRejected = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/cancellation_rejected`);
};

export const markOrderAsDsErpBillingPending = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/ds_erp_billing_pending`);
};

export const markOrderAsDispatchPending = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/dispatch_pending`);
};

export const markOrderAsPickupPending = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/pickup_pending`);
};
export const markOrderAsShipped = (
  id: number | string,
  data?: {
    prevent_notification?: boolean;
  },
) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/shipped`, data);
};

export const markOrderAsOutForDelivery = (
  id: number | string,
  data?: {
    prevent_notification?: boolean;
  },
) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/out_for_delivery`, data);
};

export const markOrderAsDeliveryAttempted = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/delivery_attempted`);
};

export const markOrderAsDeliveryFailed = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/delivery_failed`);
};

export const markOrderAsDeliveredSuccessfully = (
  id: number | string,
  data?: {
    delivery_date: string;
    prevent_notification?: boolean;
  },
) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/delivered_successfully`, data);
};

export const markOrderAsReturnRequested = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/return_requested`);
};

export const markOrderAsReturnAccepted = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/return_accepted`);
};

export const markOrderAsReturnRejected = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/return_rejected`);
};

export const markOrderAsRefundProcessing = (
  id: number | string,
  data?: {
    refund_amount: number;
    refund_remarks: string;
  },
) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/refund_processing`, data);
};

export const markOrderAsRefundComplete = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/refund_complete`);
};

export const markOrderAsProcessing = (id: number | string) => {
  return POST<Entities.Order>(`admin/orders/${id}/status/processing`);
};
