import { POST, GET } from 'utilities/AxiosHelpers';

export const markShipmentAsCancellationRequested = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/cancellation_requested`);
};

export const markShipmentAsCancellationAccepted = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/cancellation_accepted`);
};

export const markShipmentAsCancellationRejected = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/cancellation_rejected`);
};

export const markShipmentAsDispatchPending = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/dispatch_pending`);
};

export const markShipmentsAsPickupPending = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/pickup_pending`);
};

export const markShipmentsAsPickupGenerationFailed = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/pickup_generation_failed`);
};

export const markShipmentsAsBillingPending = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/billing_pending`);
};

export const markShipmentAsShipped = (
  id: number | string,
  data?: {
    prevent_notification?: boolean;
  },
) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/shipped`, data);
};

export const markShipmentAsOutForDelivery = (
  id: number | string,
  data?: {
    prevent_notification?: boolean;
  },
) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/out_for_delivery`, data);
};

export const markShipmentAsDeliveryAttempted = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/delivery_attempted`);
};

export const markShipmentAsDeliveryFailed = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/delivery_failed`);
};

export const markShipmentAsDeliveredSuccessfully = (
  id: number | string,
  data?: {
    delivery_date: string;
    prevent_notification?: boolean;
  },
) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/delivered_successfully`, data);
};

export const markShipmentAsReturnRequested = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/return_requested`);
};

export const markShipmentAsReturnAccepted = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/return_accepted`);
};

export const markShipmentAsReturnRejected = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/return_rejected`);
};

export const markShipmentAsRefundProcessing = (
  id: number | string,
  data?: {
    refund_amount: number;
    refund_remarks: string;
  },
) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/refund_processing`, data);
};

export const markShipmentAsRefundComplete = (id: number | string) => {
  return POST<Entities.Shipment>(`admin/shipments/${id}/status/refund_complete`);
};

export const uploadInvoiceApi = (
  order_id: number | string,
  id: number | string,
  formData: FormData,
) => {
  return POST<OrderInvoice>(`admin/orders/${order_id}/shipments/${id}/invoice`, formData);
};

export const createShipmentApi = (
  {
    order_id,
    shipment_id,
  }: {
    order_id: number | string;
    shipment_id: number | string;
  },
  data: {
    length?: string;
    breadth?: string;
    height?: string;
    weight?: string;
    logistics_provider?: string;
  },
) => {
  return POST<Entities.Shipment>(`admin/orders/${order_id}/shipments/${shipment_id}/ship`, data);
};

export const getPaginatedShipmentsApi = (
  page?: number,
  page_size?: number,
  queryParams?: Record<string, number | string | undefined>,
) => {
  return GET<CustomTypes.PaginatedResponse<Entities.Shipment>>('admin/shipments', {
    params: {
      latestFirst: true,
      include: 'vendor,order',
      paginate: true,
      page,
      page_size,
      ...queryParams,
    },
  });
};

export const getShipmentsApi = (
  queryParams?: { shipment_date_from: string | null; shipment_date_till: string | null } & Record<
    string,
    number | string | undefined | null
  >,
) => {
  return GET<Entities.Shipment[]>('admin/shipments', {
    params: {
      latestFirst: true,
      include: 'vendor,order',
      ...queryParams,
    },
  });
};
