const initialState = {
  sidebarShow: 'responsive',
};

const sidebarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'set':
      return { ...state, sidebarShow: payload.sidebarShow };
    default:
      return state;
  }
};

export default sidebarReducer;
