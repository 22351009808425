import COLORS from './Colors';
import SIZES from './Sizes';

const COMMON_STYLES = {
  SEARCH_INPUT_STYLE: {
    backgroundColor: 'transparent',
    borderRadius: SIZES.BASE,
    borderWidth: 2,
    borderColor: COLORS.MUTED,
    boxShadow: 'none',
    fontSize: 11,
  },
};

export default COMMON_STYLES;
