import { CCol, CRow } from '@coreui/react';
import React from 'react';
import CopyText from './CopyText';

/**
 * Component to render details of entity in Card
 * @prop    label                  Label of the field
 * @prop    detail                 Value of the field
 * @prop    classname              Class name to be passed to outer CRow component
 * @prop    options                options object for component.
 * @prop    options.valueComponent Component to be rendered instead of default value component. If passed, detail will be ignored.
 * @prop    options.detailStyle    Styles to be applied to span element wrapping the detail text
 * @prop    copyToClipboard        Boolean prop set to true if copy to clipboard functionality is required
 */
export const CardDetailRow = ({
  label,
  options,
  detail,
  className,
  copyToClipboard,
}: {
  label: string;
  detail?: string | number | null;
  className?: string;
  options?: {
    valueComponent?: JSX.Element;
    detailStyle?: React.CSSProperties;
  };
  copyToClipboard?: boolean;
}) => {
  return (
    <CRow className={`mb-1 align-items-center ${className}`}>
      <CCol xs="6" lg="4">
        <span className="text-muted">{label}:</span>
      </CCol>
      <CCol xs="6" lg="8" className="d-flex align-items-center">
        {options?.valueComponent ??
          (detail && <span style={options?.detailStyle}>{detail}</span>) ??
          'NA'}
        {copyToClipboard && <CopyText detail={detail} />}
      </CCol>
    </CRow>
  );
};
