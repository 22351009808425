import { LOGIN, LOGOUT, REFRESH_SUCCESS, REFRESH_FAILED } from 'config/actionTypes';

const initialState = {
  loggedIn: false,
};

const authReducer = (
  state = initialState,
  {
    type,
  }: {
    type: string;
    payload: ActionTypes.Auth.LoginActionPayload & ActionTypes.Auth.RefreshSuccessActionPayload;
  },
) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case REFRESH_FAILED:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
