import { CButton as CoreButton, CSpinner } from '@coreui/react';
import React from 'react';
import THEME from 'theme';

const resolveStyle = ({
  style,
  resetLineHeight,
  round,
}: {
  style?: ComponentProps.CButtonProps['style'];
  resetLineHeight?: ComponentProps.CButtonProps['resetLineHeight'];
  round?: ComponentProps.CButtonProps['round'];
}) => {
  const resolvedStyles: React.CSSProperties = style ? { ...style } : {};
  if (resetLineHeight) {
    resolvedStyles.lineHeight = 0;
  }
  if (round) {
    resolvedStyles.padding = '3px 6px';
    resolvedStyles.borderRadius = 5000;
  }
  return resolvedStyles;
};

/**
 * Custom Button component. Component takes care of handling loader display and disabling button while loading.
 * Internally uses CButton from coreui
 * @prop    {React.CSSProperties}   style                Style for button
 * @prop    {boolean}               resetLineHeight      Whether to set lineHeight to 0 inside button or not.
 * @prop    {boolean}               round                Whether to make margins as round as possible or not.
 * @prop    {boolean}               loading              Whether to display text or spinner
 * @prop    {boolean}               disabled             Prop to disable button
 * @prop    {any}                   ...rest              All other props supported by coreui's CButton
 */
export const CButton = ({
  style,
  resetLineHeight,
  round,
  loading,
  children,
  disabled,
  ...rest
}: ComponentProps.CButtonProps) => {
  const renderSpinnerOrChildren = () => {
    if (loading) {
      return (
        <CSpinner
          color={THEME.COLORS.WHITE}
          size="sm"
          style={{ marginTop: THEME.SIZES.BASE / 8, marginBottom: THEME.SIZES.BASE / 8 }}
        />
      );
    }
    return children;
  };
  return (
    <CoreButton
      style={resolveStyle({ style, resetLineHeight, round })}
      disabled={loading || disabled}
      {...rest}
    >
      {renderSpinnerOrChildren()}
    </CoreButton>
  );
};
