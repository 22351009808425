export const SOCIAL = {
  FACEBOOK: '#3B5998',
  TWITTER: '#5BC0DE',
  DRIBBBLE: '#EA4C89',
};

export const THEME = {
  PRIMARY: '#2a1ab9',
  DANGER: '#e55353',
  INFO: '#39f',
  SUCCESS: '#2eb85c',
  WARNING: '#f9b115',
  THEME: '#667EEA',
  THEME_2: '#764ba2',
  STATUS_BAR: '#6d68cc',
  LINK: '#0d77f4',
};

const COLORS = {
  WHITE: '#FFFFFF',
  DISABLED: '#eaeaea',
  LIGHT_GREY: '#cecece',
  MUTED: '#9FA5AA',
  DEFAULT: '#999999',
  GREY: '#898989',
  SEMI_DARK_GREY: '#6f6f6f',
  DARK_GREY: '#666666',
  BLACK: '#000000',
  TRANSPARENT: 'transparent',
  NEUTRAL: 'rgba(255,255,255, 0.65)',
  RED: '#f00',
  DARK_RED: '#d7350a',
  DARKER_RED: '#d50005',
  DARK_YELLOW: '#ff9800',
  LIGHT_YELLOW: '#f9f1ca',
  LIGHT_GREEN: '#ebf4d5',
  LIGHT_BLUE: '#cdf6f8',
  LIGHT_RED: '#ffe0de',
  ...THEME,
  ...SOCIAL,
};

export default COLORS;
