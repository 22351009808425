import React, { CSSProperties } from 'react';
import { CCardHeader } from '@coreui/react';

/**
 * Component to render Card Header
 * @prop    children               Content to be rendered in header
 * @prop    color                  Color for header text
 */
export const CardHeader = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color?: CSSProperties['color'];
}) => {
  return (
    <CCardHeader>
      <span className="font-weight-bold" style={{ color }}>
        {children}
      </span>
    </CCardHeader>
  );
};
