import React from 'react';
import { CModal, CModalHeader, CModalBody, CModalFooter, CSpinner } from '@coreui/react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/store';
import { CButton } from './CButton';

/**
 * Custom confirmation modal component.
 * Modal is hidden when sudoModalVisible redux variable is set to true.
 * @prop    {boolean}       show                       Whether to show modal or not
 * @prop    {function}      toggle                     Event handler which should toggle show prop's value
 * @prop    {string}        title                      Title for modal. Default = 'Confirm Delete'
 * @prop    {string}        message                    Message for modal.Default = 'Are you sure you want to delete the selected item?'
 * @prop    {function}      onCofirm                   Event handler fired when user confirms action. Does not call toggle
 * @prop    {boolean}       loading                    Prop to show spinner in confirm button
 * @prop    {string}        confirmButtonColor         Color for confirm button. Default = 'danger'
 */
export const ConfirmationModal = ({
  show,
  toggle,
  title,
  message,
  onConfirm,
  loading,
  confirmButtonColor,
}: {
  show: boolean;
  toggle: () => void;
  title?: string;
  message?: string;
  onConfirm?: () => void;
  loading?: boolean;
  confirmButtonColor?: string;
}) => {
  const sudoModalVisible = useSelector((state: RootState) => state.App.sudoModalVisible);

  return (
    <>
      <CModal show={!sudoModalVisible && show} onClose={toggle}>
        <CModalHeader closeButton>
          <span className="font-weight-bold">{title || 'Confirm Delete'}</span>
        </CModalHeader>
        <CModalBody>{message || 'Are you sure you want to delete the selected item?'}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={toggle}>
            No
          </CButton>
          {loading ? (
            <CSpinner className="mx-3" color={confirmButtonColor || 'danger'} />
          ) : (
            <CButton color={confirmButtonColor || 'danger'} onClick={onConfirm}>
              Yes
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    </>
  );
};
