import CoreIcon from '@coreui/icons-react';
import React from 'react';

const resolveStyle = ({
  style,
  fontSize,
}: {
  style?: ComponentProps.CIconProps['style'];
  fontSize?: ComponentProps.CIconProps['fontSize'];
}) => {
  const resolvedStyles: React.CSSProperties = style ? { ...style } : {};
  if (fontSize) {
    resolvedStyles.width = fontSize;
    resolvedStyles.height = fontSize;
  }
  return resolvedStyles;
};

export const CIcon = ({ style, fontSize, ...rest }: ComponentProps.CIconProps) => {
  return <CoreIcon style={resolveStyle({ style, fontSize })} {...rest} />;
};
