import { CDataTable, CElementCover, CSpinner } from '@coreui/react';
import React from 'react';
import { Paginator } from './Paginator';

/**
 * Custom DataTable component. Uses CDataTable from CoreUI internally.
 * Refer documentation of CDataTable for more details
 * https://coreui.io/react/docs/3.3/components/CDataTable
 * @prop      {boolean}     loading              Prop to show spinner instead of data
 * @prop      {array}       data                 Array of objects, data to be shown
 * @prop      {array}       fields               Array of string / object, columns to be shown
 * @prop      {object}      scopedSlots          Object for scopedSlots
 * @prop      {object}      paginationMetadata   Object containing Pagination data
 * @prop      {function}    onActivePageChange   Event handler fired when page is changed.
 */
export const DataTable = ({
  loading,
  data,
  fields,
  scopedSlots,
  paginationMetadata,
  onActivePageChange,
}: {
  loading?: boolean;
  data?: any[];
  fields: any[];
  scopedSlots?: Record<string, any>;
  paginationMetadata?: CustomTypes.PaginationMetadata;
  onActivePageChange?: (newPageNumber: number) => void;
}) => {
  return (
    <>
      <CDataTable
        items={loading ? [] : data}
        fields={fields}
        scopedSlots={scopedSlots}
        loading={loading}
        loadingSlot={
          <CElementCover
            boundaries={[
              { sides: ['top'], query: 'td' },
              { sides: ['bottom'], query: 'tbody' },
            ]}
            className="d-flex justify-content-center align-items-center"
          >
            <CSpinner color="info" />
          </CElementCover>
        }
        noItemsViewSlot={loading ? <div style={{ minHeight: 50 }} /> : undefined}
      />
      {paginationMetadata && onActivePageChange && !loading && (
        <Paginator
          activePage={paginationMetadata.current_page}
          pages={paginationMetadata.last_page}
          onActivePageChange={onActivePageChange}
        />
      )}
    </>
  );
};
