import { TOGGLE_SUDO_MODAL } from 'config/actionTypes';

const initialState: {
  sudoModalVisible: boolean;
} = {
  sudoModalVisible: false,
};

const appReducer = (
  state = initialState,
  action: ReturnType<ActionTypes.App.ToggleSudoModalAction>,
): typeof initialState => {
  switch (action.type) {
    case TOGGLE_SUDO_MODAL:
      return {
        ...state,
        sudoModalVisible: action.payload ?? !state.sudoModalVisible,
      };
    default:
      return state;
  }
};

export default appReducer;
