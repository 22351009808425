import React, { ChangeEvent, useState } from 'react';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CRow,
  CCol,
  CSpinner,
  CForm,
} from '@coreui/react';
import { getDefaultFormStateFromFormFields } from 'utilities/helpers';
import { TextInput } from 'components/common';
import { passwordValidator } from 'validators';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/store';
import { sudoLoginApi } from 'api/authApi';
import { ShowNotification } from 'utilities/NotificationManager';
import { useToggleSudoModal } from 'utilities/CustomHooks';

const formFields: GenericFormFields = {
  password: {
    defaultValue: '',
  },
};

interface FormFields {
  password?: string;
}

export const SudoModal = () => {
  const [fields, setFields] = useState<FormFields>(getDefaultFormStateFromFormFields(formFields));
  const [working, setWorking] = useState(false);
  const [invalid, setInvalid] = useState<GenericFormValidations<FormFields>>({});
  const show = useSelector((state: RootState) => state.App.sudoModalVisible);
  const toggle = useToggleSudoModal();

  const _isValid = () => {
    let valid = true;
    const invalid_fields: GenericFormValidations<FormFields> = {
      password: false,
    };
    if (!passwordValidator(fields.password)) {
      invalid_fields.password = true;
      valid = false;
    }
    setInvalid(invalid_fields);
    return valid;
  };

  const _onSubmit = () => {
    if (!_isValid()) {
      return;
    }
    setWorking(true);
    sudoLoginApi({ password: fields.password })
      .then(() => {
        toggle(false);
        setFields(getDefaultFormStateFromFormFields(formFields));
        ShowNotification('Sudo Access Granted', 'dark');
      })
      .catch((e) => {
        if (e?.data?.incorrect_password) {
          ShowNotification('Please check your password and try again', 'error');
          return;
        }
        if (e?.data?.sudo_access_not_allowed) {
          ShowNotification('Your account does not have Sudo access', 'error');
          return;
        }
        ShowNotification('Error', 'error');
      })
      .finally(() => {
        setWorking(false);
      });
  };

  return (
    <>
      <CModal show={show} onClose={() => toggle()}>
        <CModalHeader closeButton>
          <b>Confirm Password</b>
        </CModalHeader>
        <CForm
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <CModalBody>
            <p>
              This action can only be performed after obtaining <b>sudo access</b>. Please confirm
              your password to gain sudo access.
            </p>
            <CRow className="mb-2 align-items-center">
              <CCol xs="12">
                <TextInput
                  id="password_input"
                  type="password"
                  placeholder="Password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const temp: FormFields = { ...fields };
                    temp.password = e.target.value;
                    setFields(temp);
                  }}
                  value={fields.password}
                  invalid={invalid.password}
                  invalidText="Length must be number greater than 0"
                />
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => toggle()}>
              Cancel
            </CButton>
            {working ? (
              <CSpinner className="mx-3" color="primary" />
            ) : (
              <CButton
                type="submit"
                color="primary"
                onClick={() => {
                  _onSubmit();
                }}
              >
                Confirm
              </CButton>
            )}
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};
