import React from 'react';
import THEME from 'theme';

const resolveStyle = (props: PropTypes) => {
  const style: React.CSSProperties = {
    marginTop: THEME.SIZES.BASE / 2,
    marginBottom: THEME.SIZES.BASE / 2,
  };
  if (props.marginVertical) {
    style.marginTop = props.marginVertical;
    style.marginBottom = props.marginVertical;
  }
  if (props.width) {
    style.width = props.width;
  }
  return style;
};

export const Separator = (props: PropTypes) => {
  return <hr style={resolveStyle(props)} />;
};

type PropTypes = {
  marginVertical?: React.CSSProperties['marginTop'];
  width?: React.CSSProperties['width'];
};
