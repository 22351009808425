import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { THEME } from 'theme/Colors';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CopyText = ({ detail }: { detail?: string | number | null }) => {
  const [copied, setCopied] = useState(false);
  const _onCopyingText = () => {
    setCopied(true);
  };
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 500);
    }
  }, [copied]);
  return (
    <CopyToClipboard text={`${detail ?? ''}`}>
      <FontAwesomeIcon
        icon={!copied ? faCopy : faClipboardCheck}
        style={{
          fontSize: 15,
        }}
        title={!copied ? 'Copy' : 'Copied'}
        className="ml-2"
        onClick={() => _onCopyingText()}
        color={copied ? THEME.SUCCESS : undefined}
      />
    </CopyToClipboard>
  );
};

export default CopyText;
