import {
  markOrderAsShipped,
  markOrderAsOutForDelivery,
  markOrderAsDeliveryAttempted,
  markOrderAsDeliveryFailed,
  markOrderAsDeliveredSuccessfully,
  markOrderAsCancellationRequested,
  markOrderAsCancellationAccepted,
  markOrderAsCancellationRejected,
  markOrderAsReturnRequested,
  markOrderAsReturnAccepted,
  markOrderAsReturnRejected,
  markOrderAsRefundProcessing,
  markOrderAsRefundComplete,
  markOrderAsDsErpBillingPending,
  markOrderAsDispatchPending,
  markOrderAsPickupPending,
  markOrderAsProcessing,
} from 'api/orderApi';

export const ORDER_STATUSES = {
  CREATED: 1,
  PAYMENT_PENDING: 2,
  PAYMENT_SUCCESSFUL: 3,
  SHIPPED: 4,
  OUT_FOR_DELIVERY: 5,
  DELIVERY_ATTEMPTED: 6,
  DELIVERY_FAILED: 7,
  DELIVERED_SUCCESSFULLY: 8,
  CANCELLATION_REQUESTED: 9,
  CANCELLATION_ACCEPTED: 10,
  CANCELLATION_REJECTED: 11,
  RETURN_REQUESTED: 12,
  RETURN_ACCEPTED: 13,
  RETURN_REJECTED: 14,
  REFUND_PROCESSING: 15,
  REFUND_COMPLETE: 16,
  DS_ERP_ID_PENDING: 17,
  DS_ERP_BILLING_PENDING: 18,
  DISPATCH_PENDING: 19,
  PICKUP_PENDING: 20,
  PROCESSING: 21,
};

export const ORDER_STATUS_MAPPINGS: {
  [key: number]: {
    label: string;
    color: 'info' | 'primary' | 'danger' | 'warning' | 'success' | 'secondary';
    api: (...args: any[]) => Promise<Entities.Order | null>;
    notificationPreventable?: boolean;
  };
} = {
  1: {
    label: 'Created',
    color: 'secondary',
    api: () => {
      return new Promise((resolve) => {
        resolve(null);
      });
    },
  },
  2: {
    label: 'Payment Pending',
    color: 'secondary',
    api: () => {
      return new Promise((resolve) => {
        resolve(null);
      });
    },
  },
  3: {
    label: 'Payment Successful',
    color: 'primary',
    api: () => {
      return new Promise((resolve) => {
        resolve(null);
      });
    },
  },
  17: {
    label: 'ARL ID Creation Pending',
    color: 'warning',
    api: () => {
      return new Promise((resolve) => {
        resolve(null);
      });
    },
  },
  21: {
    label: 'Processing',
    color: 'warning',
    api: markOrderAsProcessing,
  },
  18: { label: 'ARL Billing Pending', color: 'warning', api: markOrderAsDsErpBillingPending },
  19: { label: 'Dispatch Pending', color: 'warning', api: markOrderAsDispatchPending },
  20: { label: 'Pickup Pending', color: 'info', api: markOrderAsPickupPending },
  4: { label: 'Shipped', color: 'info', api: markOrderAsShipped, notificationPreventable: true },
  5: {
    label: 'Out For Delivery',
    color: 'info',
    api: markOrderAsOutForDelivery,
    notificationPreventable: true,
  },
  6: { label: 'Delivery Attempted', color: 'info', api: markOrderAsDeliveryAttempted },
  7: { label: 'Delivery Failed', color: 'danger', api: markOrderAsDeliveryFailed },
  8: {
    label: 'Delivered Successfully',
    color: 'success',
    api: markOrderAsDeliveredSuccessfully,
    notificationPreventable: true,
  },
  9: { label: 'Cancellation Requested', color: 'danger', api: markOrderAsCancellationRequested },
  10: { label: 'Cancellation Accepted', color: 'primary', api: markOrderAsCancellationAccepted },
  11: { label: 'Cancellation Rejected', color: 'warning', api: markOrderAsCancellationRejected },
  12: { label: 'Return Requested', color: 'danger', api: markOrderAsReturnRequested },
  13: { label: 'Return Accepted', color: 'primary', api: markOrderAsReturnAccepted },
  14: { label: 'Return Rejected', color: 'warning', api: markOrderAsReturnRejected },
  15: { label: 'Refund Processing', color: 'primary', api: markOrderAsRefundProcessing },
  16: { label: 'Refund Complete', color: 'secondary', api: markOrderAsRefundComplete },
};

/**
 * Object to store next possible statuses that an order can take in its journey.
 * Order cannot be proceeded after RETURN_REJECTED & REFUND_COMPLETE, hence only 14 statuses are present here.
 */
export const JOURNEY_MAPPING = {
  [ORDER_STATUSES.CREATED]: [ORDER_STATUSES.PAYMENT_PENDING],
  [ORDER_STATUSES.PAYMENT_PENDING]: [ORDER_STATUSES.PAYMENT_SUCCESSFUL],
  [ORDER_STATUSES.PAYMENT_SUCCESSFUL]: [
    ORDER_STATUSES.DS_ERP_ID_PENDING,
    ORDER_STATUSES.PROCESSING,
    ORDER_STATUSES.CANCELLATION_REQUESTED,
  ],
  [ORDER_STATUSES.CANCELLATION_REQUESTED]: [
    ORDER_STATUSES.CANCELLATION_REJECTED,
    ORDER_STATUSES.CANCELLATION_ACCEPTED,
  ],
  [ORDER_STATUSES.CANCELLATION_REJECTED]: [
    ORDER_STATUSES.DS_ERP_ID_PENDING,
    ORDER_STATUSES.PROCESSING,
    ORDER_STATUSES.SHIPPED,
  ],
  [ORDER_STATUSES.CANCELLATION_ACCEPTED]: [ORDER_STATUSES.REFUND_PROCESSING],
  [ORDER_STATUSES.DS_ERP_ID_PENDING]: [
    ORDER_STATUSES.PROCESSING,
    ORDER_STATUSES.CANCELLATION_REQUESTED,
  ],
  [ORDER_STATUSES.PROCESSING]: [ORDER_STATUSES.SHIPPED, ORDER_STATUSES.CANCELLATION_REQUESTED],
  [ORDER_STATUSES.SHIPPED]: [
    ORDER_STATUSES.DELIVERED_SUCCESSFULLY,
    ORDER_STATUSES.CANCELLATION_REQUESTED,
  ],
  [ORDER_STATUSES.REFUND_PROCESSING]: [ORDER_STATUSES.REFUND_COMPLETE],
};
