import COLORS from './Colors';
import SIZES from './Sizes';
import COMMON_STYLES from './CommonStyles';

export const THEME = {
  COLORS,
  SIZES,
  COMMON_STYLES,
};

export default THEME;
