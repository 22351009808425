import { LOG } from 'config/constants';

const Logger = {
  debug: (caller: string, ...args: any[]) => {
    if (LOG) {
      console.log(`${caller}:`, ...args); // eslint-disable-line
    }
  },
};

export default Logger;
