import { LOGIN, LOGOUT, REFRESH_SUCCESS, REFRESH_FAILED } from 'config/actionTypes';

export const loginAction: ActionTypes.Auth.LoginAction = (payload) => {
  return { type: LOGIN, payload };
};

export const logoutAction: ActionTypes.Auth.LogoutAction = () => {
  return { type: LOGOUT };
};

export const refreshSuccessAction: ActionTypes.Auth.RefreshSuccessAction = (payload) => {
  return { type: REFRESH_SUCCESS, payload };
};

export const refreshFailedAction: ActionTypes.Auth.RefreshFailedAction = () => {
  return { type: REFRESH_FAILED };
};
