import React from 'react';
import RDatePicker, { ReactDatePickerProps } from 'react-datepicker';

export const DatePicker = ({ dateFormat, popperPlacement, ...rest }: ReactDatePickerProps) => {
  return (
    <RDatePicker
      dateFormat={dateFormat || 'MMM dd, yyyy'}
      popperPlacement={popperPlacement || 'bottom-end'}
      {...rest}
    />
  );
};
