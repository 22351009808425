import React from 'react';
import { CBadge } from '@coreui/react';

export const BooleanBadge = ({
  className,
  value,
  trueColor = 'primary',
  falseColor = 'secondary',
  trueText = 'Yes',
  falseText = 'No',
  pill = false,
}: {
  value?: boolean;
  trueColor?: 'danger' | 'info' | 'primary' | 'warning' | 'success' | 'default' | 'secondary';
  falseColor?: 'danger' | 'info' | 'primary' | 'warning' | 'success' | 'default' | 'secondary';
  trueText?: string;
  falseText?: string;
  pill?: boolean;
  className?: string;
}) => {
  return (
    <CBadge shape={pill ? 'pill' : ''} color={value ? trueColor : falseColor} className={className}>
      {value ? trueText : falseText}
    </CBadge>
  );
};
