import { MIN_PASSWORD_LENGTH, VENDOR_ORGANIZATION_TYPES, REGEX_PATTERN } from 'config/constants';
import moment from 'moment';
import validate from 'validate.js';
// import { PASSWORD_MINIMUM_LENGTH } from 'constants/Config';

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value: any) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format(value: any, options: any) {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  },
});

export const stringValidator = (value: any) => {
  return validate.isString(value);
};

/**
 * Function to check if value exists, only if 'dependency' also exists.
 * Dependency is considered to be non-existent if it is one of the following:
 * null
 * undefined
 * Empty strings
 * Whitespace only strings
 * Empty arrays
 * Empty objects
 * Refer http://validatejs.org/#utilities-is-empty
 * @param  value      Value to be checked
 * @param  dependency Dependency, which must exist for validator to run
 * @return            True if dependency is non-existent, or if value & dependency both exist. False otherwise.
 */
export const requiredIfExistsValidator = (value: any, dependency: any) => {
  if (!validate.isEmpty(dependency)) {
    return !validate.isEmpty(value);
  }
  return true;
};

export const requiredStringValidator = (value: any): value is string => {
  return validate.isString(value) && !validate.isEmpty(value);
};

export const emailValidator = (email: any): boolean => {
  return !validate({ email }, { email: { email: true } });
};

export const urlValidator = (url: any): boolean => {
  if (!validate.isEmpty(url)) {
    return !validate({ url }, { url: { url: true } });
  }
  return true;
};

export const pinCodeValidator = (pincode: any): boolean => {
  return !validate.single(pincode, {
    numericality: { onlyInteger: true },
    length: { is: 6 },
  });
};

export const mobileValidator = (value: any) => {
  return !validate.single(value, {
    numericality: { onlyInteger: true },
    length: { is: 10 },
  });
};

export const integerValidator = (value: any): value is number | undefined => {
  return !validate.single(value, { numericality: { onlyInteger: true } });
};

export const requiredIntegerValidator = (
  value: any,
  {
    minimum,
    maximum,
  }: {
    minimum?: number;
    maximum?: number;
  } = {},
): value is number => {
  return !validate.single(value, {
    numericality: {
      onlyInteger: true,
      ...(minimum ? { greaterThanOrEqualTo: minimum } : {}),
      ...(maximum ? { lessThanOrEqualTo: maximum } : {}),
    },
    presence: true,
  });
};

export const requiredNumberValidator = (
  value: any,
  {
    minimum,
    maximum,
  }: {
    minimum?: number;
    maximum?: number;
  } = {},
) => {
  return !validate.single(value, {
    numericality: {
      ...(minimum ? { greaterThanOrEqualTo: minimum } : {}),
      ...(maximum ? { lessThanOrEqualTo: maximum } : {}),
    },
    presence: true,
  });
};

export const passwordValidator = (value: any) => {
  return validate.isString(value) && value.length >= MIN_PASSWORD_LENGTH;
};

export const requiredValidator = (value: any) => {
  return !validate.isEmpty(value);
};

export const equalityValidator = (value1: any, value2: any) => {
  return value1 === value2;
};

export const arrayValidator = (arr: any, minLength?: number, maxLength?: number) => {
  if (!validate.isArray(arr)) {
    return false;
  }
  if (minLength && arr.length < minLength) {
    return false;
  }
  if (maxLength && arr.length > maxLength) {
    return false;
  }
  return true;
};

export const stringIsYYYYMMDD = (dateString: any): dateString is string => {
  return !validate.single(dateString, {
    datetime: {
      dateOnly: true,
    },
  });
};

const matchPattern = (pattern: RegExp, text?: string) => {
  return text && pattern.test(text);
};

export const requiredPANValidator = (pan?: any) => {
  return requiredStringValidator(pan) && matchPattern(REGEX_PATTERN.PAN, pan);
};

export const requiredGSTValidator = (gst?: any) => {
  return requiredStringValidator(gst) && matchPattern(REGEX_PATTERN.GST, gst);
};

export const requiredCINValidator = (cin?: any) => {
  return requiredStringValidator(cin) && matchPattern(REGEX_PATTERN.CIN, cin);
};

export const isPublicOrPrivateOrganization = (organizationType?: number) => {
  return (
    !!organizationType &&
    (organizationType === VENDOR_ORGANIZATION_TYPES.PRIVATE_LIMITED ||
      organizationType === VENDOR_ORGANIZATION_TYPES.PUBLIC_LIMITED)
  );
};
