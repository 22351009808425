import { LOGIN, LOGOUT, REFRESH_SUCCESS, REFRESH_FAILED } from 'config/actionTypes';

const initialState: {
  user?: Entities.User;
} = {
  user: undefined,
};

const userReducer = (
  state = initialState,
  {
    type,
    payload,
  }: {
    type: string;
    payload: ActionTypes.Auth.LoginActionPayload & ActionTypes.Auth.RefreshSuccessActionPayload;
  },
) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case LOGOUT:
      return {
        ...state,
        user: undefined,
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case REFRESH_FAILED:
      return {
        ...state,
        user: undefined,
      };
    default:
      return state;
  }
};

export default userReducer;
