import {
  markShipmentAsShipped,
  markShipmentAsOutForDelivery,
  markShipmentAsDeliveryAttempted,
  markShipmentAsDeliveryFailed,
  markShipmentAsDeliveredSuccessfully,
  markShipmentAsCancellationRequested,
  markShipmentAsCancellationAccepted,
  markShipmentAsCancellationRejected,
  markShipmentAsReturnRequested,
  markShipmentAsReturnAccepted,
  markShipmentAsReturnRejected,
  markShipmentAsRefundProcessing,
  markShipmentAsRefundComplete,
  markShipmentsAsBillingPending,
  markShipmentAsDispatchPending,
  markShipmentsAsPickupPending,
  markShipmentsAsPickupGenerationFailed,
} from 'api/shipmentApi';

export const SHIPMENT_STATUSES = {
  CREATED: 1,
  BILLING_PENDING: 2,
  DISPATCH_PENDING: 3,
  PICKUP_PENDING: 4,
  SHIPPED: 5,
  OUT_FOR_DELIVERY: 6,
  DELIVERY_ATTEMPTED: 7,
  DELIVERY_FAILED: 8,
  DELIVERED_SUCCESSFULLY: 9,
  CANCELLATION_REQUESTED: 10,
  CANCELLATION_ACCEPTED: 11,
  CANCELLATION_REJECTED: 12,
  RETURN_REQUESTED: 13,
  RETURN_ACCEPTED: 14,
  RETURN_REJECTED: 15,
  REFUND_PROCESSING: 16,
  REFUND_COMPLETE: 17,
  PICKUP_GENERATION_FAILED: 18,
};

export const SHIPMENT_STATUS_MAPPINGS: {
  [key: number]: {
    label: string;
    color: 'info' | 'primary' | 'danger' | 'warning' | 'success' | 'secondary';
    api: (...args: any[]) => Promise<Entities.Shipment | null>;
    notificationPreventable?: boolean;
  };
} = {
  1: {
    label: 'Created',
    color: 'secondary',
    api: () => {
      return new Promise((resolve) => {
        resolve(null);
      });
    },
  },
  4: { label: 'Pickup Pending', color: 'warning', api: markShipmentsAsPickupPending },
  2: { label: 'Billing Pending', color: 'warning', api: markShipmentsAsBillingPending },
  3: { label: 'Dispatch Pending', color: 'warning', api: markShipmentAsDispatchPending },
  5: { label: 'Shipped', color: 'warning', api: markShipmentAsShipped },
  6: { label: 'Out for Delivery', color: 'warning', api: markShipmentAsOutForDelivery },
  7: { label: 'Delivery Attempted', color: 'info', api: markShipmentAsDeliveryAttempted },
  8: { label: 'Delivery Failed', color: 'danger', api: markShipmentAsDeliveryFailed },
  9: {
    label: 'Delivered Successfully',
    color: 'success',
    api: markShipmentAsDeliveredSuccessfully,
    notificationPreventable: true,
  },
  10: {
    label: 'Cancellation Requested',
    color: 'danger',
    api: markShipmentAsCancellationRequested,
  },
  11: { label: 'Cancellation Accepted', color: 'primary', api: markShipmentAsCancellationAccepted },
  12: { label: 'Cancellation Rejected', color: 'warning', api: markShipmentAsCancellationRejected },
  13: { label: 'Return Requested', color: 'danger', api: markShipmentAsReturnRequested },
  14: { label: 'Return Accepted', color: 'primary', api: markShipmentAsReturnAccepted },
  15: { label: 'Return Rejected', color: 'warning', api: markShipmentAsReturnRejected },
  16: { label: 'Refund Processing', color: 'primary', api: markShipmentAsRefundProcessing },
  17: { label: 'Refund Complete', color: 'secondary', api: markShipmentAsRefundComplete },
  18: {
    label: 'Pickup Generation Failed',
    color: 'danger',
    api: markShipmentsAsPickupGenerationFailed,
  },
};

/**
 * Object to store next possible statuses that an order can take in its journey.
 * Shipment cannot be proceeded after RETURN_REJECTED & REFUND_COMPLETE, hence only 14 statuses are present here.
 */
export const JOURNEY_MAPPING = {
  [SHIPMENT_STATUSES.CREATED]: [SHIPMENT_STATUSES.BILLING_PENDING],
  [SHIPMENT_STATUSES.BILLING_PENDING]: [
    SHIPMENT_STATUSES.DISPATCH_PENDING,
    SHIPMENT_STATUSES.CANCELLATION_REQUESTED,
  ],
  [SHIPMENT_STATUSES.DISPATCH_PENDING]: [
    SHIPMENT_STATUSES.PICKUP_PENDING,
    SHIPMENT_STATUSES.PICKUP_GENERATION_FAILED,
    SHIPMENT_STATUSES.CANCELLATION_REQUESTED,
  ],
  [SHIPMENT_STATUSES.PICKUP_GENERATION_FAILED]: [
    SHIPMENT_STATUSES.PICKUP_PENDING,
    SHIPMENT_STATUSES.CANCELLATION_REQUESTED,
  ],
  [SHIPMENT_STATUSES.PICKUP_PENDING]: [
    SHIPMENT_STATUSES.SHIPPED,
    SHIPMENT_STATUSES.CANCELLATION_REQUESTED,
  ],
  [SHIPMENT_STATUSES.SHIPPED]: [SHIPMENT_STATUSES.OUT_FOR_DELIVERY],
  [SHIPMENT_STATUSES.CANCELLATION_ACCEPTED]: [SHIPMENT_STATUSES.REFUND_PROCESSING],
  [SHIPMENT_STATUSES.CANCELLATION_REQUESTED]: [
    SHIPMENT_STATUSES.CANCELLATION_ACCEPTED,
    SHIPMENT_STATUSES.CANCELLATION_REJECTED,
  ],
  [SHIPMENT_STATUSES.CANCELLATION_REJECTED]: [SHIPMENT_STATUSES.PICKUP_PENDING],
  [SHIPMENT_STATUSES.OUT_FOR_DELIVERY]: [
    SHIPMENT_STATUSES.DELIVERED_SUCCESSFULLY,
    SHIPMENT_STATUSES.DELIVERY_ATTEMPTED,
    SHIPMENT_STATUSES.DELIVERY_FAILED,
  ],
  [SHIPMENT_STATUSES.DELIVERY_ATTEMPTED]: [
    SHIPMENT_STATUSES.DELIVERY_FAILED,
    SHIPMENT_STATUSES.DELIVERED_SUCCESSFULLY,
  ],
  [SHIPMENT_STATUSES.DELIVERY_FAILED]: [SHIPMENT_STATUSES.OUT_FOR_DELIVERY],
  [SHIPMENT_STATUSES.DELIVERED_SUCCESSFULLY]: [SHIPMENT_STATUSES.RETURN_REQUESTED],
  [SHIPMENT_STATUSES.RETURN_REQUESTED]: [
    SHIPMENT_STATUSES.RETURN_REJECTED,
    SHIPMENT_STATUSES.RETURN_ACCEPTED,
  ],
  [SHIPMENT_STATUSES.RETURN_ACCEPTED]: [SHIPMENT_STATUSES.REFUND_PROCESSING],
  [SHIPMENT_STATUSES.REFUND_PROCESSING]: [SHIPMENT_STATUSES.REFUND_COMPLETE],
};
