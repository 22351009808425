export const LOG = false;
export const DEV_MODE = false;
export const DEV_URL = 'http://localhost:3333/api/v1';
export const PROD_URL = 'https://api.qwiggo.com/api/v1';
export const BASE_URL = DEV_MODE ? DEV_URL : PROD_URL;
export const INITIATE_FORGOT_PASSWORD_KEY = 'INITIATE_FORGOT_PASSWORD_KEY';
export const PAGINATION_DEFAULT_PAGE_SIZE = 10;
export const ROLES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
};
export const MIN_PASSWORD_LENGTH = 6;

/**
 * Types of assets handled by app.
 */
export const APPLICATION_ASSET_TYPES = {
  BANNER: 1,
};

/**
 * File Types of assets handled by app.
 */
export const APPLICATION_ASSET_FILE_TYPES = {
  IMAGE_ASSET: 'IMAGE_ASSET',
};

/**
 * Supported Logistics Providers
 */
export const LOGISTICS_PROVIDERS = {
  SHIPROCKET: 'SHIPROCKET',
};

export const SHIPROCKET_ORDER_STATUSES = {
  NEW_ORDER: 1,
  READY_TO_SHIP: 3,
  PICKUP_SCHEDULED: 4,
  CANCELLED: 5,
  CANCELLATION_REQUESTED: 18,
  OUT_FOR_PICKUP: 34,
  PICKED_UP: 51,
  SHIPPED: 6,
  IN_TRANSIT: 20,
  REACHED_AT_DESTINATION_HUB: 43,
  OUT_FOR_DELIVERY: 19,
  DELIVERED: 7,
  SELF_FULFILLED: 52,
} as const;

export const VENDOR_ORGANIZATION_TYPES = {
  PROPRIETORSHIP: 1,
  PARTNERSHIP: 2,
  LLP: 3,
  PRIVATE_LIMITED: 4,
  PUBLIC_LIMITED: 5,
};

export const VENDOR_ORGANIZATION_TYPE_MAPPINGS: {
  [key: number]: string;
} = {
  1: 'Proprietorship',
  2: 'Partnership',
  3: 'LLP',
  4: 'Private Limited',
  5: 'Public Limited',
};

export const SHIPROCKET_ORDER_STATUS_MAPPINGS: {
  [key: number]: {
    label: string;
    color: 'info' | 'primary' | 'danger' | 'warning' | 'success' | 'secondary';
  };
} = {
  1: { label: 'Created', color: 'info' },
  3: { label: 'Ready To Ship', color: 'primary' },
  4: { label: 'Pickup Scheduled', color: 'primary' },
  5: { label: 'Cancelled', color: 'secondary' },
  18: { label: 'Cancellation Requested', color: 'secondary' },
  34: { label: 'Out For Pickup', color: 'primary' },
  51: { label: 'Picked Up', color: 'secondary' },
  6: { label: 'Shipped', color: 'secondary' },
  20: { label: 'In Transit', color: 'secondary' },
  43: { label: 'Reached Destination Hub', color: 'secondary' },
  19: { label: 'Out For Delivery', color: 'primary' },
  7: { label: 'Delivered', color: 'success' },
  52: { label: 'Self Fulfilled', color: 'secondary' },
};

export const FEEDBACK_TYPE_MAPPING = {
  1: 'Suggestion',
  2: 'Bug Report',
  3: 'Enquiry',
} as const;

export const ORDER_EVENT_TYPES: {
  [key: number]: {
    label: string;
  };
} = {
  1: { label: 'Created' },
  2: { label: 'Payment Successful' },
  3: { label: 'Payment Failed' },
  18: { label: 'DS ERIP ID Pending' },
  19: { label: 'DS ERIP Billing Pending' },
  20: { label: 'Dispatch Pending' },
  23: { label: 'Pickup Pending' },
  21: { label: 'Logistic Order Generated' },
  22: { label: 'Logistic Order Generation Failed' },
  4: { label: 'Shipped' },
  5: { label: 'Tracking Update' },
  6: { label: 'Out For Delivery' },
  7: { label: 'Delivery Attempted' },
  8: { label: 'Delivery Failed' },
  9: { label: 'Delivered Successfully' },
  10: { label: 'Cancellation Requested' },
  11: { label: 'Cancellation Accepted' },
  12: { label: 'Cancellation Rejected' },
  13: { label: 'Return Requested' },
  14: { label: 'Return Accepted' },
  15: { label: 'Return Rejected' },
  16: { label: 'Refund Processing' },
  17: { label: 'Refund Complete' },
  24: { label: 'Processing' },
} as const;

export const SERVICE_REQUEST_STATUSES: {
  [key: number]: string;
} = {
  1: 'Open',
  2: 'Processing',
  3: 'Waiting for Customer',
  4: 'Waiting for Admin',
  5: 'Closed',
  6: 'Cancelled',
};

export const SERVICE_REQUEST_TYPE_MAPPINGS = {
  1: 'Kyc Verification',
  2: 'New Product Approval',
};

export const SERVICE_REQUEST_TYPES = {
  KYC_VERIFICATION: 1,
  NEW_PRODUCT_APPROVAL: 2,
};

export const REGEX_PATTERN = {
  PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
  GST: /^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([A-Z0-9]){1}([Z]){1}([A-Z0-9]){1}$/,
  CIN: /^([LU]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$/,
  PIN_CODE: /([0-9]{6})/,
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
};

/**
 * Id that will be returned by backend for derived shipments of orders that were created before
 * Multi vendor implmentattion
 */
export const LEGACY_ORDER_SHIPMENT_ID = -1;

export const MONTHS: {
  [key: number]: string;
} = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
