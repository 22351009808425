import React from 'react';
import { CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CTooltip } from '@coreui/react';
import THEME from 'theme';
import CIcon from '@coreui/icons-react';

const renderInputPrefixes = (prefixOptions: {
  showRupeeSymbol?: boolean;
  showMobilePrefix?: boolean;
  showInfoIcon?: boolean;
  onInfoPrefixClick?: () => void;
  infoIconTooltipContent?: any;
}) => {
  if (prefixOptions.showRupeeSymbol) {
    return (
      <CInputGroupPrepend>
        <CInputGroupText>₹</CInputGroupText>
      </CInputGroupPrepend>
    );
  }
  if (prefixOptions.showMobilePrefix) {
    return (
      <CInputGroupPrepend>
        <CInputGroupText>+91</CInputGroupText>
      </CInputGroupPrepend>
    );
  }
  if (prefixOptions.showInfoIcon) {
    return (
      <>
        <CTooltip content={prefixOptions.infoIconTooltipContent?.() ?? 'Click me'} placement="top">
          <CInputGroupPrepend
            onClick={() => {
              prefixOptions.onInfoPrefixClick?.();
            }}
            style={{ cursor: 'pointer' }}
          >
            <CInputGroupText>
              <CIcon name="cil-info" />
            </CInputGroupText>
          </CInputGroupPrepend>
        </CTooltip>
      </>
    );
  }
};

/**
 * Component to render row with text input in Card
 * @prop    id                     Id to be passed to CInput.id
 * @prop    placeholder            Place holder for input
 * @prop    value                  Value variable
 * @prop    onChange               Event handler fired on value change
 * @prop    onBlur                 Event handler fired on input blur
 * @prop    invalid                Boolean to be used to determine whether to apply invalid styles or not
 * @prop    type                   Type of input
 * @prop    name                   Name of input
 * @prop    disabled               If true, input would be disabled
 * @prop    showRupeeSymbol        If true, Rupee symbol is prepended to input using CInputGroup
 * @prop    showMobilePrefix       If true, +91 is prepended to input using CInputGroup
 * @prop    showInfoIcon           If true, Info icon is prepended to input
 * @prop    onPrefixClick          a function that handles what happens when the user clicks on the prefix icon
 * @prop    infoIconTooltipContent the content to be shown in the tool tip. Defaults to "Click Me"
 * @prop    style                  Style object for input
 * @prop    invalidText            Text displayed below input when invalid is true
 * @prop    autoComplete           Any valid html value for auto-complete attribute
 */
export const TextInput = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  invalid,
  type = 'text',
  disabled = false,
  showRupeeSymbol = false,
  showMobilePrefix = false,
  showInfoIcon = false,
  onInfoPrefixClick = undefined,
  infoIconTooltipContent = undefined,
  style,
  invalidText,
  autoComplete,
  required,
}: ComponentProps.TextInputProps) => {
  return (
    <>
      <CInputGroup>
        {renderInputPrefixes({
          showRupeeSymbol,
          showMobilePrefix,
          showInfoIcon,
          onInfoPrefixClick,
          infoIconTooltipContent,
        })}
        <CInput
          autoComplete={autoComplete}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={style}
          type={type}
          disabled={disabled}
          onBlur={onBlur}
          invalid={invalid}
          required={required}
        />
      </CInputGroup>
      {invalid && invalidText && (
        <span style={{ color: THEME.COLORS.DANGER }} className="small">
          {invalidText}
        </span>
      )}
    </>
  );
};
