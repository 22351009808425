import React from 'react';
import { CPagination } from '@coreui/react';

export const Paginator = ({
  activePage,
  pages,
  onActivePageChange,
  size = 'sm',
  align = 'center',
}: {
  activePage: number;
  pages: number;
  onActivePageChange: (newPage: number) => void;
  size?: CPagination['size'];
  align?: CPagination['align'];
}) => {
  if (pages < 1) {
    return null;
  }
  return (
    <>
      <CPagination
        activePage={activePage}
        pages={pages}
        onActivePageChange={onActivePageChange}
        align={align}
        size={size}
      />
    </>
  );
};
