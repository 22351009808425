import { toast } from 'react-toastify';

type ToastTypes = 'success' | 'error' | 'warning' | 'info' | 'default' | 'dark';

/**
 * Method to show notification that self-dismisses in 'duration'.
 * @param   {string}        message             Message for notification
 * @param   {string}        type                Type of toast, one of ToastTypes types
 * @param   {number}        options.duration    Duration after which toast dismisses. In milliseconds
 * @return  {void}
 */
export const ShowNotification = (
  message: string,
  type: ToastTypes = 'default',
  options?: { duration: number },
) => {
  toast(message, {
    autoClose: options?.duration || 3000,
    type,
  });
};

/**
 * Method to show notification that does not self-dismiss.
 * @param   {string}        message             Message for notification
 * @param   {string}        type                Type of toast, one of ToastTypes types
 * @return  {void}
 */
export const ShowPersistentNotification = (message: string, type: ToastTypes = 'default') => {
  toast(message, {
    autoClose: false,
    type,
  });
};
